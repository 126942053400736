import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import LeadForm from "../../components/Contact";

const ExercisesTemplate = ({ pageContext, data, location }) => {

  // const currentPage = () => {
  //   return location.pathname.replace(pageContext.localizedUrls[pageContext.locale], '').replace(/\//g, '')
  // }

  return (
    <Layout context={pageContext} openGraph={{
      url: pageContext.localizedUrls[pageContext.locale],
      title: pageContext.title,
      description: pageContext.description,
      images: [
        {
          url: 'https://www.spinecentre.com.hk/_img/banners/exercise.jpg'
        }
      ]
    }}>
      <PageHeader
        title={data.firestore.page.content[pageContext.locale]['headerTitle']}
        imageUrl="/_img/banners/exercise.jpg"
      />
      <main className="my-12 flex justify-between w-full flex-col">
        <div className="mt-12 lg:px-44 max-w-lg mx-auto grid gap-8 lg:grid-cols-3 lg:max-w-none">
          {data.firestore.exercises.map((exercise) => (
            <Link key={exercise.id} to={`/${pageContext.locale}/about-chiropractic/exercises/${exercise.href}`} className="flex flex-col mx-8 lg:mx-0 rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img className="h-48 lg:h-64 w-full object-cover" src={exercise.thumbnail} alt="" />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <div className="block mt-2">
                    <p className="text-lg lg:text-xl font-semibold text-gray-900">{exercise.title}</p>
                    <p className="mt-3 text-sm lg:text-base text-gray-500">{exercise.description}</p>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
        {/* <div className="mt-12 flex justify-center">
          <span className="relative z-0 inline-flex shadow-sm rounded-md">
            <a
              className={`relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 ${pageContext.hasPreviousPage?'cursor-pointer':'cursor-not-allowed'} hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-mango focus:border-mango`}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>
            {
              Array.from({ length: pageContext.numberOfPages }).map((_, i) => (
                <a
                  key={i===0?pageContext.localizedUrls[pageContext.locale]:`${pageContext.localizedUrls[pageContext.locale]}/${i+1}`}
                  href={i===0?pageContext.localizedUrls[pageContext.locale]:`${pageContext.localizedUrls[pageContext.locale]}/${i+1}`}
                  className={`${(currentPage()===''&&i===0)||parseInt(currentPage())===i+1?'text-mango':'text-gray-700'} -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-mango focus:border-mango`}
                >
                  {i+1}
                </a>
              ))
            }
            <a
              className={`-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 ${pageContext.hasNextPage?'cursor-pointer':'cursor-not-allowed'} hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-mango focus:border-mango`}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </span>
        </div> */}
      </main>
      <LeadForm locale={pageContext.locale} />
    </Layout>
  )
}

export default ExercisesTemplate

export const query = graphql`
  query ExercisesTemplateQuery($pageId: ID, $locale: String!) {
    firestore {
      page(id: $pageId) {
        content
      }
      exercises(locale: $locale) {
        id
        href
        title
        description
        thumbnail
      }
    }
  }
`